<template>
  <div style="width: 100%">
    <bread-crumb :breadcrumb-items="breadcrumbsItems" />

    <!-- v-for="{ title, image } in contents"
      :key="title" -->
    <div class="content section-padding" data-aos="fade-up">
      <div class="container default-padding">
        <img
          :src="
            require(`@/assets/images/defaults/products-and-services__background.jpg`)
          "
          alt="Content Image"
          class="image"
        />
        <div>
          <div class="primary-heading">{{ aboutContent.title }}</div>
          <div v-html="aboutContent.content"></div>
        </div>
      </div>
    </div>

    <div class="content section-padding" data-aos="fade-up">
      <div class="container default-padding">
        <img
          :src="
            require(`@/assets/images/defaults/products-and-services__background.jpg`)
          "
          alt="Content Image"
          class="image"
        />
        <div>
          <div class="primary-heading">{{ visionContent.title }}</div>
          <div v-html="visionContent.content"></div>
        </div>
      </div>
    </div>

    <div class="content section-padding" data-aos="fade-up">
      <div class="container default-padding">
        <img
          :src="
            require(`@/assets/images/defaults/products-and-services__background.jpg`)
          "
          alt="Content Image"
          class="image"
        />
        <div>
          <div class="primary-heading">{{ missionContent.title }}</div>
          <div v-html="missionContent.content"></div>
        </div>
      </div>
    </div>

    <get-a-quote />

    <horizontal-client-slider />
  </div>
</template>

<script>
  import { labels } from "@/common";
  import HorizontalClientSlider from "@/components/HorizontalClientSlider.vue";
  import BreadCrumb from "@/components/BreadCrumb.vue";
  import GetAQuote from "../components/GetAQuote.vue";
  import { mapState } from "vuex";

  export default {
    name: "AboutUs",

    components: {
      HorizontalClientSlider,
      BreadCrumb,
      GetAQuote,
    },

    data() {
      return {
        labels,
        breadcrumbsItems: [{ name: "About Us", path: "" }],
        contents: [
          {
            title: "About Us",
            content: "",
            image: "images/defaults/products-and-services__background.jpg",
          },
          {
            title: "Vision",
            content: "",
            image: "images/defaults/products-and-services__background.jpg",
          },
          {
            title: "Mission",
            content: "",
            image: "images/defaults/products-and-services__background.jpg",
          },
        ],
      };
    },

    computed: {
      ...mapState({
        allContents: (state) => state.editableContent.editableContents.data,
      }),

      aboutContent() {
        return (
          this.allContents &&
          this.allContents.find((c) => c.title.toLowerCase() == "about us")
        );
      },

      visionContent() {
        return (
          this.allContents &&
          this.allContents.find((c) => c.title.toLowerCase() == "vision")
        );
      },

      missionContent() {
        return (
          this.allContents &&
          this.allContents.find((c) => c.title.toLowerCase() == "mission")
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/main.scss";

  .content {
    .image {
      height: 200px;
      width: 50%;
      float: right;
      margin-left: 4rem;
      margin-bottom: 2rem;
      object-fit: cover;
      margin-left: 2rem;
    }

    &:nth-child(odd) {
      background: $background-color;
      .image {
        float: left;
        margin-left: 0rem;
        margin-right: 4rem;
      }
    }
  }

  @media only screen and (max-device-width: 768px) {
    .content .image {
      height: 200px;
      float: none;
      width: 100%;
      margin: 0;
      margin-bottom: 3rem;
    }
  }
</style>
